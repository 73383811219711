
jQuery(function($) {
    $(document).ready(function() {





        $(".menu").click(function() {
            $(".bignav").fadeToggle("fast", "linear");
        });
        $(".bignav_list li a").click(function() {
            $(".bignav").fadeToggle("fast", "linear");
        });
        $(".bignav_list li a").click(function() {
            $(".menu").toggleClass('change');
        });
        $(".menu").click(function() {
            $(this).toggleClass('change');
        });

        /**
         * Modals
         */
        $(".modal-link").click(function(e) {
            e.preventDefault();
            e.stopPropagation();
            var title, href;

            if (this.tagName === 'A') {
                href = this.href;
                if (this.innerHTML.match(/[cC]licki?n?g? here/) === null) {
                    title = this.innerHTML;
                } else {
                    title = this.href.split('/').pop();
                }
            }

            if ($(this).attr('data-modal-title')) {
                title = $(this).attr('data-modal-title');
            }

            $("#modal").on('show.bs.modal', function(e) {
                if (title) {
                    $(this).find('.modal-title').text(title);
                }
                if (href) {
                    var h = window.innerHeight * 0.75;
                    var embed = '<img src="' + href + '" style ="width: 100%; height: auto;"/>';
                    //var embed = '<iframe id="theIFrame" src="' + href + '" width="100%" height="' + h + '" type="text/html" pluginspage="http://www.adobe.com/products/acrobat/readstep2.html" />';

                    $(this).find('.modal-body').html(embed);
                    //console.log(frames["theIFrame"].document);
                }
            }).modal();
        });

        /**
         * Collapse News Menu
         */
        $('.collapse').on('show.bs.collapse', function() {
            $(this).parent().addClass('open').removeClass('closed').siblings().each(function() {
                $(this).removeClass('open').addClass('closed').children('.collapse').collapse('hide');
            });
        }).on('hide.bs.collapse', function() {
            $(this).parent().addClass('closed').removeClass('open');
        });

        /**
         * Map pins
         */
        // function getPinColor(pinEl) {
        //     for (var className, i = 0; className = pinEl.classList[i]; i++) {
        //         if (className !== 'pin' && className !== 'active') {
        //             return className;
        //         }
        //     }
        //     return null;
        // }
        //
        // if ($('.pin.active').length) {
        //     var lastColor = getPinColor($('.pin.active')[0]);
        //     var $pinName = $("#pinName");
        //     var $pinAddress1 = $("#pinAddress1");
        //     var $pinAddress2 = $("#pinAddress2");
        //     var $pinAddress3 = $("#pinAddress3");
        //     var $pinCity = $("#pinCity");
        //     var $pinState = $("#pinState");
        //     var $pinCountry = $("#pinCountry");
        //     var $pinZip = $("#pinZip");
        //     var $pinPhone = $("#pinPhone");
        //
        //     $('.map .pin').click(function(e) {
        //         var color = getPinColor(this);
        //
        //         $(this).addClass('active')
        //             .siblings().removeClass('active');
        //
        //         //$("#pinColor").removeClass(lastColor).addClass(color);
        //
        //         lastColor = color;
        //
        //         var data = $(this).data();
        //
        //         $pinName.text(data.name);
        //
        //         if (data.address1) {
        //             $pinAddress1.show().children('span').text(data.address1);
        //             $('#btn_getDirections').show();
        //         } else {
        //             $pinAddress1.hide();
        //             $('#btn_getDirections').hide();
        //         }
        //
        //         if (data.address2) {
        //             $pinAddress2.show().children('span').text(data.address2);
        //         } else {
        //             $pinAddress2.hide();
        //         }
        //
        //         if (data.address3) {
        //             $pinAddress3.show().children('span').text(data.address3);
        //         } else {
        //             $pinAddress3.hide();
        //         }
        //
        //         if (data.city) {
        //             $pinCity.show().text(data.city + ',');
        //         } else {
        //             $pinCity.hide();
        //         }
        //
        //         if (data.state) {
        //             $pinState.show().text(data.state);
        //         } else {
        //             $pinState.hide();
        //         }
        //
        //
        //         if (data.country) {
        //             $pinCountry.show().text(data.country);
        //         } else {
        //             $pinCountry.hide();
        //         }
        //
        //         if (data.zip) {
        //             $pinZip.show().text(data.zip);
        //         } else {
        //             $pinZip.hide();
        //         }
        //
        //         if (data.phone) {
        //             $pinPhone.show().text(data.phone);
        //         } else {
        //             $pinPhone.hide();
        //         }
        //     });
        // }

        /**
         * Jp Slider
         */
        $('[jp-slider]').each(function() {
            $(this).jpSlider();
        });

        /**
         * Contact Form
         */
        $('.form-control-country').change(function() {
            var country = $(this).val();
            $.get('/ajax/countrystate/' + country, null, function(response) {
                var options = [];
                for (var key in response) {
                    var stateName = response[key];
                    options.push(stateName);
                }
                var optionsMap = options.map(function(val) {
                    return '<option value="' + val + '">' + val + '</option>';
                });
                $('.form-control-state').html(optionsMap);
            });
        });

        $('#videoModal').on('shown.bs.modal', function() {
            console.log('opened video modal');
        });
    });
});
